.brands_list_2col,
.brands_list_3col {
  display: flex;
  flex-wrap: wrap;
  width: 1070px;
  margin: 0 auto;
  li {
    margin-right: 40px;
    margin-bottom: 50px;
  }
  @include mqSP {
    display: block;
    padding: 0 15px;
    width: 100%;
    li {
      margin: 0 0 35px 0;
    }
  }
}
.brands_list_2col {
  li {
    width: 515px;
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
  @include mqSP {
    li {
      width: 100%;
    }
  }
}
.brands_list_3col {
  li {
    width: 330px;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
  @include mqSP {
    margin-bottom: 40px;
    li {
      width: 100%;
    }
  }
}

.brands_list_pic {
  line-height: 0;
  margin: 0 0 10px 0;
  text-align: center;
  img {
    max-width: 100%;
  }
  @include mqSP {
    margin-bottom: 20px;
  }
}
.brands_list_name {
  font-size: 22px;
  padding-left: 15px;
  border-left: 4px solid #1040a9;
  margin: 0 0 10px 0;
  @include mqSP {
    font-size: 18px;
  }
}
.brands_list_note {
  font-size: 16px;
  @include mqSP {
    font-size: 13px;
  }
}

.brands_bg {
  background-image: url(../img/ourbrands-bg.png);
  background-position: left bottom;
  background-size: auto;
  background-repeat: no-repeat;
}