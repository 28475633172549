.pc-hide {
  @include mqPC {
    display: none;
  }
}
.sp-hide {
  @include mqSP {
    display: none !important;
  }
}
.mb50 {
  margin-bottom: 50px !important;
  @include mqSP {
    margin-bottom: 25px !important;
  }
}
.mb35 {
  margin-bottom: 35px !important;
  @include mqSP {
    margin-bottom: 20px !important;
  }
}
.txtC {
  text-align: center !important;
}
.ma {
  margin-left: auto !important;
  margin-right: auto !important;
}