@charset "UTF-8";
@-webkit-keyframes animation {
  100% {
    opacity: 1; } }

@keyframes animation {
  100% {
    opacity: 1; } }

@-webkit-keyframes animation2 {
  100% {
    opacity: 1;
    top: 0; } }

@keyframes animation2 {
  100% {
    opacity: 1;
    top: 0; } }

@-webkit-keyframes animation3 {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.1); } }

@keyframes animation3 {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.1); } }

/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  /*  font-weight: normal;*/
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

ul, ol {
  list-style: none; }

a {
  margin: 0;
  padding: 0;
  outline: none;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input, select {
  vertical-align: middle; }

input[type="submit"],
input[type="reset"],
button,
select {
  cursor: pointer;
  -webkit-appearance: none;
  outline: none; }

img {
  vertical-align: bottom;
  outline: none;
  outline: 0; }

button {
  border: none;
  outline: none;
  margin: 0;
  padding: 0; }

address {
  font-style: normal; }

/*---------------------------------------------------------*/
/* ベース */
/*---------------------------------------------------------*/
html, body {
  width: 100%;
  height: 100%; }

html {
  font-size: 62.5%; }

body {
  position: relative;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  -webkit-text-size-adjust: 100%;
  word-wrap: break-word;
  color: #000;
  font-size: 14px; }
  body.is-fixed {
    position: fixed; }

* {
  box-sizing: border-box; }

a {
  color: inherit;
  text-decoration: none;
  transition: all .2s ease; }
  a:hover {
    text-decoration: none;
    opacity: 0.7; }

img {
  vertical-align: bottom; }

small {
  font-size: 11px;
  font-size: 1.1rem; }

.l-wrap {
  background: url(../img/top-bg.png) repeat; }

.l-article {
  max-width: 800px;
  margin: 0 auto; }
  .l-article h1 {
    font-size: 30px;
    margin: 0 0 40px 0;
    padding: 30px 0;
    border-bottom: 1px solid #000; }
  @media screen and (max-width: 767px) {
    .l-article {
      padding: 0 15px; }
      .l-article h1 {
        font-size: 17px;
        margin: 0 0 20px 0;
        padding: 15px 0; } }

.article-date {
  font-size: 16px; }
  @media screen and (max-width: 767px) {
    .article-date {
      font-size: 12px; } }

.article-body {
  font-size: 16px;
  line-height: 1.875;
  margin-bottom: 100px; }
  .article-body p {
    margin-bottom: 30px; }
  @media screen and (max-width: 767px) {
    .article-body {
      font-size: 14px;
      margin-bottom: 50px; }
      .article-body p {
        margin-bottom: 15px; } }

.brands_list_2col,
.brands_list_3col {
  display: flex;
  flex-wrap: wrap;
  width: 1070px;
  margin: 0 auto; }
  .brands_list_2col li,
  .brands_list_3col li {
    margin-right: 40px;
    margin-bottom: 50px; }
  @media screen and (max-width: 767px) {
    .brands_list_2col,
    .brands_list_3col {
      display: block;
      padding: 0 15px;
      width: 100%; }
      .brands_list_2col li,
      .brands_list_3col li {
        margin: 0 0 35px 0; } }

.brands_list_2col li {
  width: 515px; }
  .brands_list_2col li:nth-child(2n) {
    margin-right: 0; }

@media screen and (max-width: 767px) {
  .brands_list_2col li {
    width: 100%; } }

.brands_list_3col li {
  width: 330px; }
  .brands_list_3col li:nth-child(3n) {
    margin-right: 0; }

@media screen and (max-width: 767px) {
  .brands_list_3col {
    margin-bottom: 40px; }
    .brands_list_3col li {
      width: 100%; } }

.brands_list_pic {
  line-height: 0;
  margin: 0 0 10px 0;
  text-align: center; }
  .brands_list_pic img {
    max-width: 100%; }
  @media screen and (max-width: 767px) {
    .brands_list_pic {
      margin-bottom: 20px; } }

.brands_list_name {
  font-size: 22px;
  padding-left: 15px;
  border-left: 4px solid #1040a9;
  margin: 0 0 10px 0; }
  @media screen and (max-width: 767px) {
    .brands_list_name {
      font-size: 18px; } }

.brands_list_note {
  font-size: 16px; }
  @media screen and (max-width: 767px) {
    .brands_list_note {
      font-size: 13px; } }

.brands_bg {
  background-image: url(../img/ourbrands-bg.png);
  background-position: left bottom;
  background-size: auto;
  background-repeat: no-repeat; }

.l-breadcrumb {
  width: 1070px;
  margin: 0 auto 70px auto;
  padding: 15px 0; }
  .l-breadcrumb ul li {
    display: inline;
    font-size: 13px;
    color: #4c4c4c; }
    .l-breadcrumb ul li:after {
      content: "";
      display: inline-block;
      width: 6px;
      height: 11px;
      margin: 0 10px;
      background-image: url(../img/breadcrumb-arrow.png);
      background-repeat: no-repeat; }
    .l-breadcrumb ul li:last-child:after {
      display: none; }
    .l-breadcrumb ul li a {
      text-decoration: underline; }
  @media screen and (max-width: 767px) {
    .l-breadcrumb {
      width: 100%;
      padding: 15px 10px;
      margin-bottom: 20px; }
      .l-breadcrumb ul li {
        font-size: 10px; }
        .l-breadcrumb ul li:after {
          width: 3px;
          height: 6px;
          margin: 0 10px;
          background-size: 3px auto; } }

.exporting_list {
  width: 600px;
  margin: 0 auto 110px auto; }
  .exporting_list li {
    position: relative;
    border: 2px solid rgba(144, 193, 228, 0.8);
    background: rgba(233, 237, 242, 0.8);
    border-radius: 8px;
    padding: 15px 0;
    text-align: center;
    font-size: 30px;
    line-height: 1.57;
    color: #05427f;
    margin: 0 auto 48px auto; }
    .exporting_list li:after {
      content: "";
      position: absolute;
      bottom: -48px;
      left: 0;
      width: 100%;
      height: 48px;
      background-image: url(../img/arrow-list.png);
      background-position: center;
      background-repeat: no-repeat; }
    .exporting_list li:last-child {
      margin-bottom: 0; }
      .exporting_list li:last-child:after {
        display: none; }
    .exporting_list li p {
      font-size: 16px;
      color: #000; }
      .exporting_list li p a {
        color: #05427f;
        text-decoration: underline; }
        .exporting_list li p a:hover {
          text-decoration: none; }
    .exporting_list li span {
      font-size: 20px;
      padding: 0 0 0 10px; }
      br + .exporting_list li span {
        padding: 0; }
  @media screen and (max-width: 767px) {
    .exporting_list {
      width: 100%;
      padding: 0 15px;
      margin: 0 auto 30px auto; }
      .exporting_list li {
        font-size: 20px; }
        .exporting_list li:after {
          background-size: 42px auto; }
        .exporting_list li p {
          font-size: 13px; }
        .exporting_list li span {
          font-size: 13px; } }

.button {
  position: relative;
  display: block;
  width: 214px;
  height: 42px;
  margin: 45px auto 0 auto;
  font-size: 16px;
  text-align: center;
  color: #fff;
  line-height: 42px;
  cursor: pointer; }
  .button:hover {
    opacity: .7; }
  .button:before, .button:after {
    content: "";
    position: absolute;
    z-index: 0;
    width: 216px;
    background: #211E55;
    height: 32px; }
  .button:before {
    left: -6px;
    bottom: 0; }
  .button:after {
    right: -6px;
    top: 0; }
  .button span {
    position: relative;
    z-index: 1;
    display: block;
    width: 216px;
    height: 42px; }
    .button span:before, .button span:after {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid; }
    .button span:before {
      top: 0;
      left: -6px;
      border-width: 0 0 10px 10px;
      border-color: transparent transparent #211E55; }
    .button span:after {
      bottom: 0;
      right: -4px;
      border-width: 10px 10px 0 0;
      border-color: #211E55 transparent transparent transparent; }
  @media screen and (max-width: 767px) {
    .button {
      margin-top: 25px; } }

.button-icon {
  position: absolute;
  right: 5px;
  top: 0;
  z-index: 2;
  width: 8px;
  height: 100%;
  background-image: url(../img/icon-arrow_sp.png);
  background-position: center;
  background-size: 7px auto;
  background-repeat: no-repeat; }

.exporting-bg {
  background-image: url(../img/exporting-bg.png);
  background-position: left 220px;
  background-size: 100% auto;
  background-repeat: no-repeat; }
  @media screen and (max-width: 767px) {
    .exporting-bg {
      background: none; } }

.l-footer {
  min-width: 1070px;
  background: #1a1a1a; }
  @media screen and (max-width: 767px) {
    .l-footer {
      min-width: 0; } }

.footer-inner {
  position: relative;
  width: 1070px;
  margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .footer-inner {
      width: 100%; } }

.footer-instagram {
  display: none; }
  @media screen and (max-width: 767px) {
    .footer-instagram {
      display: block;
      width: 100%;
      height: 65px;
      border-bottom: 1px solid #a4a4a4; }
      .footer-instagram a {
        display: block;
        width: 100%;
        height: 65px;
        background-image: url(../img/icon-insta.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 25px auto; } }

.footer-heading {
  color: #fff;
  font-size: 20px;
  padding: 40px 0; }
  @media screen and (max-width: 767px) {
    .footer-heading {
      text-align: center;
      padding: 20px 10px 10px 0;
      font-size: 16px; } }

.footer-nav {
  position: relative;
  border-bottom: 1px solid #a4a4a4; }
  .footer-nav ul {
    *zoom: 1;
    position: absolute;
    right: 0;
    top: 45px; }
    .footer-nav ul:after {
      content: "";
      display: table;
      clear: both; }
    .footer-nav ul li {
      float: left;
      font-size: 14px; }
      .footer-nav ul li:before {
        content: "|";
        color: #fff; }
      .footer-nav ul li:first-child:before {
        display: none; }
      .footer-nav ul li a {
        color: #fff;
        padding: 0 15px; }
      .footer-nav ul li:last-child a {
        padding-right: 0; }
  @media screen and (max-width: 767px) {
    .footer-nav ul {
      position: static;
      padding-bottom: 15px; }
      .footer-nav ul li {
        float: none;
        font-size: 12px; }
        .footer-nav ul li:before {
          display: none; }
        .footer-nav ul li a {
          display: block;
          text-decoration: underline;
          padding: 6px 10px; } }

.footer-copy {
  color: #808080;
  font-size: 11px;
  padding: 25px 0; }
  .footer-copy p {
    width: 1070px;
    margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .footer-copy {
      padding: 17px 0;
      font-size: 9px; }
      .footer-copy p {
        width: 100%;
        text-align: center; } }

.l-header {
  width: 100%;
  min-width: 1070px;
  height: 150px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); }
  .l-header:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 1070px;
    height: 32px;
    background: #1a1a1a; }
  .l-header.is-fixed {
    position: fixed;
    z-index: 2;
    top: -200px;
    height: 110px;
    transition: top .3s ease; }
  .l-header.is-show {
    top: 0; }
  @media screen and (max-width: 767px) {
    .l-header {
      position: relative !important;
      z-index: 1;
      min-width: 0;
      height: 60px;
      overflow: hidden; }
      .l-header:before {
        height: 4px; }
      .l-header.is-open {
        overflow: visible; } }

.header-inner {
  position: relative;
  width: 1070px;
  margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .header-inner {
      width: 100%; } }

.header-hamburger {
  display: none; }
  @media screen and (max-width: 767px) {
    .header-hamburger {
      display: block;
      position: absolute;
      top: 13px;
      right: 10px; }
      .header-hamburger a {
        display: inline-block;
        font-size: 0;
        overflow: hidden;
        vertical-align: middle;
        white-space: nowrap;
        text-indent: 100%;
        display: block;
        width: 30px;
        height: 32px;
        background-image: url(../img/icon-menu.png);
        background-repeat: no-repeat;
        background-size: 30px auto;
        background-position: 0 0; }
        .is-open .header-hamburger a {
          width: 27px;
          height: 32px;
          background-image: url(../img/icon-close.png);
          background-size: 27px auto; } }

@media screen and (max-width: 767px) {
  .header-menu {
    position: absolute;
    z-index: 0;
    top: 60px;
    left: 0;
    width: 100%;
    opacity: 0;
    transition: opacity .2s ease; }
    .is-open .header-menu {
      opacity: 1; } }

.header-nav {
  position: absolute;
  right: 0;
  top: 84px; }
  .header-nav ul {
    *zoom: 1; }
    .header-nav ul:after {
      content: "";
      display: table;
      clear: both; }
    .header-nav ul li {
      float: left;
      font-size: 16px; }
      .header-nav ul li:before {
        content: "|";
        color: #000; }
      .header-nav ul li:first-child:before {
        display: none; }
      .header-nav ul li a {
        color: #000;
        padding: 0 15px; }
        .header-nav ul li a.is-current {
          color: #211E55;
          text-decoration: underline;
          pointer-events: none; }
      .header-nav ul li:last-child a {
        padding-right: 0; }
  .l-header.is-fixed .header-nav {
    top: 65px; }
  @media screen and (max-width: 767px) {
    .header-nav {
      position: static;
      width: 100%;
      background: rgba(33, 30, 85, 0.95); }
      .header-nav ul li {
        float: none; }
        .header-nav ul li:before {
          display: none; }
        .header-nav ul li a {
          display: block;
          padding: 15px 10px;
          border-bottom: 1px solid #999;
          font-size: 15px;
          color: #fff; }
          .header-nav ul li a.is-current {
            color: #fff;
            pointer-events: auto;
            text-decoration: none; } }

.header-lang-tite {
  display: none; }
  @media screen and (max-width: 767px) {
    .header-lang-tite {
      display: block;
      padding: 12px 10px 8px 10px;
      font-size: 15px;
      color: #fff; } }

.header-lang ul {
  *zoom: 1;
  position: absolute;
  right: 30px;
  top: 10px; }
  .header-lang ul:after {
    content: "";
    display: table;
    clear: both; }
  .header-lang ul li {
    float: left;
    font-size: 12px; }
    .header-lang ul li:before {
      content: "|";
      color: #808080; }
    .header-lang ul li:first-child:before {
      display: none; }
    .header-lang ul li a {
      color: #808080;
      padding: 0 10px; }
      .header-lang ul li a.is-current {
        pointer-events: none;
        color: #fff; }
      .header-lang ul li a:hover {
        color: #fff; }
    .header-lang ul li:last-child a {
      padding-right: 0; }

@media screen and (max-width: 767px) {
  .header-lang {
    width: 100%;
    padding: 0 0 12px 0;
    background: rgba(13, 51, 135, 0.95);
    border-bottom: 1px solid #999; }
    .header-lang ul {
      position: static; }
      .header-lang ul li {
        font-size: 12px; } }

.header-instagram {
  position: absolute;
  right: 0;
  top: 7px;
  width: 18px;
  height: 18px; }
  .header-instagram a {
    display: inline-block;
    font-size: 0;
    overflow: hidden;
    vertical-align: middle;
    white-space: nowrap;
    text-indent: 100%;
    width: 18px;
    height: 18px;
    background-image: url(../img/icon-insta.png);
    background-repeat: no-repeat;
    background-size: 18px auto;
    background-position: 0 0; }
  @media screen and (max-width: 767px) {
    .header-instagram {
      display: none; } }

.header-logo {
  position: absolute;
  left: 0;
  top: 44px; }
  .header-logo a {
    display: inline-block;
    font-size: 0;
    overflow: hidden;
    vertical-align: middle;
    white-space: nowrap;
    text-indent: 100%;
    width: 80px;
    height: 98px;
    background-image: url(../img/logo.png);
    background-repeat: no-repeat;
    background-size: auto 98px;
    background-position: 0 0; }
  .l-header.is-fixed .header-logo a {
    width: 206px;
    height: 56px;
    background-image: url(../img/logo_scroll.png);
    background-size: auto 56px; }
  @media screen and (max-width: 767px) {
    .header-logo {
      top: 13px;
      left: 10px; }
      .header-logo a,
      .header-logo .l-header.is-fixed a {
        width: 131px;
        height: 39px;
        background-image: url(../img/logo_sp.png);
        background-size: auto 39px; } }

.form-section__body ._txt_top {
  margin: 0 0 45px 0;
  padding: 0 0 30px 0;
  font-size: 16px;
  text-align: center;
  background-image: url(../img/mark.png);
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 40px auto; }

.form-section__body ._txt {
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 50px; }
  .form-section__body ._txt:last-child {
    margin-bottom: 0;
    color: #808080;
    font-size: 15px;
    line-height: 1.466; }
  .form-section__body ._txt a {
    text-decoration: underline; }

.form-section__body ._txt_bottom {
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  margin: 40px 0 50px 0; }

.form-section__body sup {
  font-size: 5px;
  vertical-align: super;
  color: #cd2929; }

@media screen and (max-width: 767px) {
  .form-section {
    padding: 0 20px;
    width: calc(100% - 20px);
    margin: 0 auto 20px auto; }
    .form-section__body ._txt_top {
      font-size: 12px;
      line-height: 22px;
      text-align: left; }
    .form-section__body ._txt {
      font-size: 12px;
      line-height: 22px;
      text-align: left;
      margin-bottom: 20px; }
      .form-section__body ._txt:last-child {
        font-size: 12px; }
    .form-section__body ._txt_bottom {
      font-size: 12px;
      line-height: 22px;
      text-align: left;
      margin-bottom: 20px; }
    .form-section__body sup {
      font-size: 5px; } }

.form-cont input.is-error, .form-cont ._form-message dd textarea.is-error {
  background-color: #fff7f8;
  background-image: url(../img/icon_error.png);
  background-repeat: no-repeat;
  background-size: 18px auto;
  background-position: 98% center;
  border: 1px solid #cd2929; }

.form-cont {
  display: table;
  width: 685px;
  margin: 0 auto; }
  .form-cont .error {
    display: block;
    font-size: 13px;
    padding-top: 5px;
    color: #cd2929; }
  .form-cont dl {
    display: table-row; }
  .form-cont dt, .form-cont dd {
    display: table-cell;
    font-size: 16px;
    padding: 15px 15px 15px 0; }
  .form-cont input {
    font-size: 14px;
    padding: 10px;
    border: 1px solid #CCCCCC; }
  .form-cont input {
    width: 500px; }
  .form-cont ._form-message dt {
    vertical-align: top; }
  .form-cont ._form-message dd {
    padding-bottom: 0; }
    .form-cont ._form-message dd textarea {
      font-size: 14px;
      width: 500px;
      height: 300px;
      border: 1px solid #CCCCCC; }
  @media screen and (max-width: 767px) {
    .form-cont {
      width: 100%; }
      .form-cont dl {
        display: block; }
      .form-cont dt {
        text-align: left; }
      .form-cont dt, .form-cont dd {
        display: block;
        font-size: 14px;
        padding: 10px 0px 0 0; }
      .form-cont ._form-name input {
        width: 100%; }
      .form-cont ._form-mail input,
      .form-cont ._form-mail-confirmation input {
        width: 100%; }
      .form-cont ._form-message dd textarea {
        width: 100%;
        height: 150px; } }

.form-btn {
  width: 100%;
  margin: 40px 0 20px 0; }
  .form-btn .button {
    position: relative; }
    .form-btn .button input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      opacity: 0; }
  @media screen and (max-width: 767px) {
    .form-btn {
      margin: 20px 0; } }

input:focus,
textarea:focus {
  background: #eaf0fc;
  box-shadow: 0px 0px 7px #254792;
  outline: none; }

.l-top-kv {
  position: relative;
  top: 0px;
  z-index: 0;
  width: 100%;
  height: 490px;
  overflow: hidden; }
  @media screen and (min-width: 415px) {
    .l-header.is-fixed + .l-top-kv {
      margin-top: 150px; } }
  @media screen and (max-width: 767px) {
    .l-top-kv {
      height: 320px; } }

.top-kv-inner {
  width: 100%;
  height: 490px; }
  @media screen and (max-width: 767px) {
    .top-kv-inner {
      height: 320px; } }

.top-kv-slide {
  position: relative;
  width: 100%;
  height: 490px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  @media screen and (max-width: 767px) {
    .top-kv-slide {
      height: 320px; } }
  .top-kv-slide.slide01 {
    background-image: url(../img/main1.png); }
    @media screen and (max-width: 767px) {
      .top-kv-slide.slide01 {
        background-image: url(../img/main1_sp.png); } }
  .top-kv-slide.slide02 {
    background-image: url(../img/main2.png); }
    @media screen and (max-width: 767px) {
      .top-kv-slide.slide02 {
        background-image: url(../img/main2_sp.png); } }
  .top-kv-slide.slide03 {
    background-image: url(../img/main3.png); }
    @media screen and (max-width: 767px) {
      .top-kv-slide.slide03 {
        background-image: url(../img/main3_sp.png); } }

.top-kv-copy {
  text-shadow: 1px 1px 10px #000;
  font-weight: bold;
  line-height: 1.2;
  color: #fff;
  font-size: 40px;
  width: 1070px;
  margin: 0 auto;
  padding: 60px 30px; }
  .top-kv-copy span {
    font-size: 50px; }
  @media screen and (min-width: 415px) {
    .slide03 .top-kv-copy {
      padding-top: 80px; } }
  @media screen and (max-width: 767px) {
    .top-kv-copy {
      position: absolute;
      top: 60%;
      left: 0;
      width: calc(100% - 20px);
      padding: 0 10px;
      font-size: 18px;
      text-shadow: none;
      line-height: 1.1; }
      .top-kv-copy span {
        font-size: 23px; }
      .zh-tw .top-kv-copy,
      .zh-cn .top-kv-copy {
        font-size: 19px;
        line-height: 1.3; }
        .zh-tw .top-kv-copy span,
        .zh-cn .top-kv-copy span {
          font-size: 25px; }
      .slide01 .top-kv-copy:after {
        content: "";
        position: absolute;
        bottom: -15px;
        left: 10px;
        width: 50px;
        height: 1px;
        background: #fff; }
      .slide03 .top-kv-copy {
        line-height: 1.3; } }

.l-main {
  padding: 0 0 100px 0; }
  @media screen and (max-width: 767px) {
    .l-main {
      padding: 0 0 50px 0; } }

.l-page-header {
  position: relative;
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center; }
  .l-page-header.page-header_inquiry {
    background-image: url(../img/inquiry-heading.png); }
  .l-page-header.page-header_brands {
    background-image: url(../img/ourbrands-heading.png); }
  .l-page-header.page-header_exporting {
    background-image: url(../img/exporting-heading.png); }
  .l-page-header.page-header_notfound {
    background-image: url(../img/inquiry-heading.png); }
  .l-page-header.page-header_policy {
    background-image: url(../img/policy-heading.png); }
  .l-page-header.page-header_plants {
    background-image: url(../img/plants-heading.png); }
  .l-page-header.page-header_fes {
    background-image: url(../img/fes-heading.png); }
  .l-page-header.page-header_about {
    background-image: url(../img/about-heading.png); }
  .l-page-header.page-header_information {
    background-image: url(../img/information-heading.png); }
  .l-page-header span {
    display: block;
    text-align: center;
    color: #fff;
    width: 100%;
    text-shadow: 0px 0px 4.65px rgba(7, 0, 2, 0.75);
    font-size: 36px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;
    letter-spacing: .08em; }
  .l-page-header:before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: #211E55;
    opacity: .67; }
  @media screen and (max-width: 767px) {
    .l-page-header {
      height: 100px; }
      .l-page-header span {
        font-size: 21px; } }

.page-section ._txt {
  width: 1000px;
  margin: 0 auto 50px auto;
  text-align: center;
  font-size: 16px;
  line-height: 30px; }
  .page-section ._txt:last-child {
    margin-bottom: 0;
    color: #808080;
    font-size: 15px;
    line-height: 1.466; }
  .page-section ._txt img {
    max-width: 100%; }
  .page-section ._txt a {
    text-decoration: underline; }
  .page-section ._txt span {
    width: 800px;
    display: block;
    font-size: 13px;
    padding: 20px 0 0 0;
    margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .page-section ._txt {
      width: 100%;
      font-size: 12px;
      line-height: 22px;
      text-align: left;
      margin-bottom: 20px;
      padding: 0 15px; }
      .page-section ._txt span {
        width: 100%;
        font-size: 10px; } }

.page-section ._txt_sub {
  font-size: 14px;
  width: 1000px;
  margin: -20px auto 50px auto;
  text-align: center;
  color: #666; }
  @media screen and (max-width: 767px) {
    .page-section ._txt_sub {
      width: 100%;
      margin: 0 auto;
      font-size: 12px; } }

.page-section ._txt_big {
  font-size: 20px;
  width: 1000px;
  margin: 80px auto;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .page-section ._txt_big {
      width: 100%;
      padding: 0 15px;
      box-sizing: border-box;
      font-size: 16px;
      margin: 40px auto; } }

.page-heading {
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 10px 0; }
  @media screen and (max-width: 767px) {
    .page-heading {
      font-size: 16px; } }

.page-content_2col {
  display: flex;
  flex-direction: row-reverse;
  width: 1072px;
  margin: 0 auto 100px auto; }
  .page-content_2col img {
    max-width: 440px; }
  .page-content_2col ._txt {
    text-align: left;
    font-size: 16px;
    line-height: 1.875;
    padding: 0 40px 0 0;
    flex: 1;
    color: #000 !important;
    line-height: 1.875 !important; }
  @media screen and (max-width: 767px) {
    .page-content_2col {
      width: 100%;
      padding: 0 15px;
      box-sizing: border-box;
      display: block;
      margin-bottom: 40px; }
      .page-content_2col img {
        max-width: 100%; }
      .page-content_2col ._txt {
        margin-top: 15px;
        padding: 0;
        font-size: 12px !important; } }

.page-content_2col_reverce {
  display: flex;
  width: 1072px;
  margin: 0 auto 100px auto; }
  .page-content_2col_reverce img {
    max-width: 440px; }
  .page-content_2col_reverce ._txt {
    text-align: left;
    font-size: 16px;
    line-height: 1.875;
    padding: 0 0 0 40px;
    flex: 1;
    color: #000 !important;
    line-height: 1.875 !important; }
  @media screen and (max-width: 767px) {
    .page-content_2col_reverce {
      width: 100%;
      padding: 0 15px;
      box-sizing: border-box;
      display: block;
      margin-bottom: 40px; }
      .page-content_2col_reverce img {
        max-width: 100%; }
      .page-content_2col_reverce ._txt {
        margin-top: 15px;
        font-size: 12px !important;
        padding: 0; } }

.page-content_2col_reverce_map {
  display: flex;
  width: 1072px;
  margin: 0 auto 100px auto; }
  .page-content_2col_reverce_map img {
    max-width: 600px; }
  .page-content_2col_reverce_map ._txt {
    text-align: left;
    line-height: 1.875;
    padding: 0 0 0 40px;
    flex: 1;
    color: #000 !important;
    line-height: 1.875 !important; }
    .page-content_2col_reverce_map ._txt h3 {
      color: #1040a9;
      font-size: 18px; }
    .page-content_2col_reverce_map ._txt p {
      margin: 0 0 20px 0;
      font-size: 14px; }
      .page-content_2col_reverce_map ._txt p:last-child {
        margin-bottom: 0; }
  @media screen and (max-width: 767px) {
    .page-content_2col_reverce_map {
      width: 100%;
      display: block;
      padding: 0 15px; }
      .page-content_2col_reverce_map img {
        max-width: 100%; }
      .page-content_2col_reverce_map ._txt {
        margin: 15px 0 0 0;
        padding: 0; }
        .page-content_2col_reverce_map ._txt h3 {
          font-size: 14px; }
        .page-content_2col_reverce_map ._txt p {
          font-size: 12px; } }

.page-content_3col {
  display: flex;
  justify-content: space-between;
  width: 1072px;
  margin: 0 auto 100px auto;
  align-items: center; }
  .page-content_3col img {
    max-width: 100%; }
  @media screen and (max-width: 767px) {
    .page-content_3col {
      width: 100%;
      display: block;
      text-align: center; }
      .page-content_3col img {
        margin: 0 0 5px 0; } }

.page-content-products {
  display: flex;
  width: 1072px;
  margin: 0 auto 60px auto; }
  .page-content-products:last-child {
    margin-bottom: 0; }
  .page-content-products > p img {
    width: 400px; }
  .page-content-products ._txt {
    position: relative;
    flex: 1;
    padding: 0 180px 0 40px; }
    .page-content-products ._txt h3 {
      font-size: 24px;
      text-align: left;
      color: #000;
      margin: 0 0 20px 0; }
    .page-content-products ._txt p {
      text-align: left;
      font-size: 15px;
      color: #000 !important;
      line-height: 1.875 !important;
      padding: 0 0 30px 0; }
      .page-content-products ._txt p.name {
        position: absolute;
        bottom: 0;
        right: 180px;
        padding: 0; }
    .page-content-products ._txt img {
      position: absolute;
      right: 0;
      bottom: 0; }
  @media screen and (max-width: 767px) {
    .page-content-products {
      display: block;
      padding: 0 15px;
      width: 100%;
      box-sizing: border-box;
      text-align: center; }
      .page-content-products > p img {
        max-width: 100%;
        width: 100%; }
      .page-content-products ._txt {
        margin-top: 15px;
        margin: 15px auto 0 auto;
        padding: 0 110px 0 0; }
        .page-content-products ._txt h3 {
          font-size: 16px;
          margin-bottom: 15px; }
        .page-content-products ._txt p {
          font-size: 13px; }
          .page-content-products ._txt p.name {
            position: static;
            text-align: right; }
        .page-content-products ._txt img {
          width: 90px; } }

.pagetop {
  position: fixed;
  right: 40px;
  bottom: 40px;
  opacity: 0;
  transition: all .3s ease; }
  .pagetop a {
    display: inline-block;
    font-size: 0;
    overflow: hidden;
    vertical-align: middle;
    white-space: nowrap;
    text-indent: 100%;
    display: block;
    width: 76px;
    height: 76px;
    background-color: rgba(0, 0, 0, 0.8);
    background-image: url(../img/pagetop.png);
    background-size: 58px auto;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 6px; }
  .pagetop.is-show {
    opacity: 1; }
  @media screen and (max-width: 767px) {
    .pagetop {
      width: 46px;
      height: 46px;
      right: 10px;
      bottom: 15px; }
      .pagetop a {
        width: 46px;
        height: 46px;
        background-color: rgba(51, 51, 51, 0.8);
        background-size: 29px auto; } }

.section-heading {
  margin: 0 0 45px 0;
  padding: 0 0 30px 0;
  font-size: 40px;
  text-align: center;
  line-height: 1.3;
  background-image: url(../img/mark.png);
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 40px auto; }
  @media screen and (max-width: 767px) {
    .section-heading {
      font-size: 21px;
      margin: 0 0 15px 0;
      padding: 0 10px 25px 10px;
      background-image: url(../img/mark_sp.png);
      background-size: 30px auto; } }

.section-text {
  font-size: 16px;
  line-height: 1.875;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .section-text {
      padding: 0 10px;
      font-size: 12px; } }

.section-inner {
  width: 1070px;
  margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .section-inner {
      width: 100%; } }

.section-heading_sub {
  font-size: 39px;
  margin: 80px 0 45px 0;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .section-heading_sub {
      font-size: 20px;
      margin: 20px 0 15px 0; } }

.section-heading_green {
  font-size: 30px;
  color: #91cd02;
  text-align: center;
  margin: 0 0 40px 0; }
  @media screen and (max-width: 767px) {
    .section-heading_green {
      font-size: 20px;
      margin: 30px 0 15px 0; } }

.section-heading_blue {
  font-size: 26px;
  color: #0d3387;
  margin: 0 0 30px 0;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .section-heading_blue {
      font-size: 18px;
      line-height: 1.4; } }

.section_white {
  background: #fff;
  border: 2px solid #e6e6e6;
  padding: 35px;
  text-align: center;
  width: 1072px;
  margin: 100px auto; }
  .section_white img {
    max-width: 100%; }
  @media screen and (max-width: 767px) {
    .section_white {
      width: calc(100% - 30px);
      padding: 15px;
      margin: 40px auto; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.dot-class {
  position: absolute;
  bottom: 20px;
  text-align: center;
  width: 100%; }
  .dot-class li {
    position: relative;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 8px;
    border-radius: 50%;
    overflow: hidden;
    text-indent: 100%;
    overflow: hidden;
    word-wrap: initial;
    font-size: 0;
    border: 1px solid #fff;
    cursor: pointer; }
    .dot-class li.slick-active {
      background: #fff; }
    .dot-class li button {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%; }
  @media screen and (max-width: 767px) {
    .dot-class {
      bottom: 10px; } }

.slick-arrow {
  position: absolute;
  z-index: 999;
  top: 50%;
  -webkit-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  width: 30px;
  height: 30px;
  /* background-color: white; */
  transform-origin: center center;
  -webkit-transform-origin: top left;
  cursor: pointer;
  background: none;
  text-indent: 100%;
  overflow: hidden;
  word-wrap: initial;
  font-size: 0; }
  @media screen and (max-width: 767px) {
    .slick-arrow {
      opacity: 0; } }

.slick-next {
  right: 3.5%;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border-bottom: 2px solid white;
  border-right: 2px solid white; }

.slick-prev {
  left: 2.5%;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border-top: 2px solid white;
  border-left: 2px solid white; }

.table_fes {
  display: flex;
  width: 800px;
  margin: 0 auto 20px auto; }
  .table_fes dt {
    width: 150px;
    font-size: 16px;
    border-right: 2px solid #1040a9;
    color: #1040a9;
    padding: 15px 0; }
  .table_fes dd {
    flex: 1;
    font-size: 16px;
    line-height: 1.5;
    padding: 15px 0 15px 30px; }
    .table_fes dd a {
      color: #0d3387;
      text-decoration: underline; }
  @media screen and (max-width: 767px) {
    .table_fes {
      display: block;
      width: 100%;
      padding: 0 15px;
      box-sizing: border-box; }
      .table_fes dt {
        display: inline-block;
        border-right: none;
        border-bottom: 2px solid #1040a9;
        font-size: 14px; }
      .table_fes dd {
        width: 100%;
        font-size: 12px;
        padding: 15px 0; } }

.l-top-about {
  position: relative;
  height: auto;
  padding: 0; }
  .l-top-about:after {
    content: "";
    display: block;
    position: relative;
    bottom: 0;
    width: 100%;
    height: 240px;
    margin: 0;
    background-image: url(../img/top-pic.png);
    background-repeat: repeat-x;
    background-position: center; }
  .l-top-about .section-inner {
    padding: 100px 0 50px 0;
    background: url(../img/top-about-bg.png) no-repeat left top; }
  @media screen and (max-width: 767px) {
    .l-top-about {
      padding: 50px 0 0 0; }
      .l-top-about:after {
        background-image: url(../img/top-pic_sp.png);
        background-size: cover;
        height: 250px; }
      .l-top-about .section-inner {
        padding: 50px 0;
        background-image: url(../img/top-about-bg_sp.png);
        background-size: cover;
        background-position: left bottom; } }

.l-top-export {
  position: relative;
  background: url(../img/top-export-bg-base.png) repeat; }
  .l-top-export .top-export-inner {
    background: url(../img/top-export-bg.png) no-repeat right top;
    height: 537px;
    padding: 145px 0 0 0; }
    .l-top-export .top-export-inner .section-heading,
    .l-top-export .top-export-inner .section-text,
    .l-top-export .top-export-inner .button-wrapper {
      width: 539px; }
    .l-top-export .top-export-inner .section-heading {
      text-align: left; }
  @media screen and (max-width: 767px) {
    .l-top-export .top-export-inner {
      padding: 50px 0 0 0;
      background-image: url(../img/top-export-bg_sp.png);
      background-size: cover; }
      .l-top-export .top-export-inner .section-heading,
      .l-top-export .top-export-inner .section-text,
      .l-top-export .top-export-inner .button-wrapper {
        width: 100%; }
      .l-top-export .top-export-inner .section-heading {
        text-align: center; }
      .l-top-export .top-export-inner .button {
        margin-top: 280px; } }

.l-top-contact {
  padding: 100px 0 0 0; }
  @media screen and (max-width: 767px) {
    .l-top-contact {
      padding-top: 50px; } }

.page_policy {
  width: 800px;
  margin: 0 auto;
  font-size: 16px; }
  .page_policy h3 {
    margin: 45px 0 15px 0;
    font-size: 19px;
    font-weight: bold; }
  .page_policy p {
    line-height: 1.875; }
  .page_policy ul {
    margin: 10px 0 0 0; }
    .page_policy ul li {
      margin-bottom: 10px;
      position: relative;
      padding: 0 0 0 18px; }
      .page_policy ul li:before {
        content: "・";
        position: absolute;
        top: 0;
        left: 0; }
  @media screen and (max-width: 767px) {
    .page_policy {
      width: 100%;
      padding: 0 15px;
      font-size: 12px; }
      .page_policy h3 {
        font-size: 16px;
        margin: 20px 0 15px 0; } }

.lang-ja .l-header {
  z-index: 3; }
  @media screen and (max-width: 767px) {
    .lang-ja .l-header .header-lang {
      background: rgba(33, 30, 85, 0.95); } }
  .lang-ja .l-header.is-pageTop {
    height: 32px;
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden; }
    @media screen and (max-width: 767px) {
      .lang-ja .l-header.is-pageTop {
        position: fixed !important;
        overflow: visible;
        height: 60px; } }
  @media screen and (max-width: 767px) {
    .lang-ja .l-header.is-fixed {
      position: fixed !important;
      height: 60px !important; }
      .lang-ja .l-header.is-fixed .header-logo a {
        width: 131px;
        height: 39px;
        background-image: url(../img/logo_sp.png);
        background-size: auto 39px; } }

.lang-ja .l-top-kv {
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  z-index: 10;
  transition: all 0.5s cubic-bezier(1, 0, 0, 1); }
  .lang-ja .l-top-kv.is-scroll {
    top: -200vh; }

.lang-ja .top-kv-inner {
  height: 100%; }

.lang-ja .top-kv-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }

.lang-ja .top-kv-slideshow {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0;
  -webkit-animation: animation 0.5s ease 0s 1 forwards;
  animation: animation 0.5s ease 0s 1 forwards; }
  .lang-ja .top-kv-slideshow > div {
    opacity: 0;
    transition: opacity .3s ease; }
  .lang-ja .top-kv-slideshow.is-loaded > div {
    position: absolute;
    opacity: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    z-index: 0; }
    .lang-ja .top-kv-slideshow.is-loaded > div.is-current {
      z-index: 2;
      -webkit-animation: animation3 10s ease 0s 1 forwards;
      animation: animation3 10s ease 0s 1 forwards; }
    .lang-ja .top-kv-slideshow.is-loaded > div.is-next {
      z-index: 1; }

.lang-ja .top-kv-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  padding-top: 15%; }
  @media screen and (max-width: 767px) {
    .lang-ja .top-kv-inner {
      padding-top: 35%; } }
  .lang-ja .top-kv-inner h1 {
    display: none;
    text-shadow: 1px 1px 10px #000;
    color: #fff;
    font-size: 40px;
    text-align: center;
    margin-bottom: 30px;
    letter-spacing: .02em; }
    .lang-ja .top-kv-inner h1 span {
      transform: scale(0.8);
      display: inline-block;
      transition: all 1s ease;
      opacity: 0; }
      .lang-ja .top-kv-inner h1 span.is-show {
        transform: scale(1);
        display: inline-block;
        opacity: 1; }
    @media screen and (max-width: 767px) {
      .lang-ja .top-kv-inner h1 {
        font-size: 25px;
        line-height: 2;
        width: 27rem;
        text-align: center;
        margin: 0 auto 5% auto; } }
  .lang-ja .top-kv-inner h2 {
    text-shadow: 1px 1px 10px #000;
    color: #fff;
    font-size: 18px;
    text-align: center;
    line-height: 1.8em;
    position: relative;
    opacity: 0;
    top: 15px;
    -webkit-animation: animation2 1.5s ease 7s 1 forwards;
    animation: animation2 1.5s ease 7s 1 forwards; }
    @media screen and (max-width: 767px) {
      .lang-ja .top-kv-inner h2 {
        font-size: 14px;
        line-height: 2; } }
  .lang-ja .top-kv-inner .logo {
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    opacity: 0;
    -webkit-animation: animation 3s ease 8s 1 forwards;
    animation: animation 3s ease 8s 1 forwards; }
    @media screen and (max-width: 767px) {
      .lang-ja .top-kv-inner .logo {
        display: none; } }
  .lang-ja .top-kv-inner .scroll {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    opacity: 0;
    -webkit-animation: animation 0.7s ease 9s 1 forwards;
    animation: animation 0.7s ease 9s 1 forwards; }

.lang-ja .l-header.is-fixed + .l-top-kv {
  margin-top: 0; }

.lang-ja .l-main {
  top: calc(100vh + 500px);
  position: relative;
  z-index: 99;
  background: url(../img/top-bg.png); }

.lang-ja .l-top-information .section-inner {
  padding: 60px 0;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.lang-ja .l-top-information h1 {
  position: relative;
  font-size: 26px;
  line-height: 1;
  width: 350px;
  font-weight: bold; }
  .lang-ja .l-top-information h1:after {
    content: "";
    width: 60px;
    height: 1px;
    background: #000;
    position: absolute;
    top: 50%;
    right: 40px; }

.lang-ja .l-top-information ul {
  width: 100%;
  flex: 1; }
  .lang-ja .l-top-information ul li {
    margin: 0 0 10px 0;
    font-size: 16px; }
    .lang-ja .l-top-information ul li:lats-child {
      margin-bottom: 0; }
    .lang-ja .l-top-information ul li a {
      text-decoration: underline; }

@media screen and (max-width: 767px) {
  .lang-ja .l-top-information {
    padding: 40px 0; }
    .lang-ja .l-top-information .section-inner {
      display: block;
      padding: 0 10px; }
    .lang-ja .l-top-information h1 {
      font-size: 21px;
      width: 100%;
      text-align: center;
      margin-bottom: 30px; }
      .lang-ja .l-top-information h1:after {
        display: none; }
    .lang-ja .l-top-information ul li {
      font-size: 12px;
      line-height: 1.6; } }

.lang-ja .l-top-description {
  position: relative;
  background: #eef3f9 !important; }
  .lang-ja .l-top-description .top-description-inner {
    background: url(../img/top-description-bg.png) no-repeat right top;
    height: 537px;
    padding: 145px 0 0 0; }
    .lang-ja .l-top-description .top-description-inner .section-heading,
    .lang-ja .l-top-description .top-description-inner .section-text,
    .lang-ja .l-top-description .top-description-inner .button-wrapper {
      width: 539px; }
  @media screen and (max-width: 767px) {
    .lang-ja .l-top-description .top-description-inner {
      padding: 50px 0 0 0;
      background-image: url(../img/top-description-bg_sp.png);
      background-size: 80% auto;
      background-position: bottom center;
      height: 570px; }
      .lang-ja .l-top-description .top-description-inner .section-heading,
      .lang-ja .l-top-description .top-description-inner .section-text,
      .lang-ja .l-top-description .top-description-inner .button-wrapper {
        width: 100%; }
      .lang-ja .l-top-description .top-description-inner .section-heading {
        text-align: center; }
      .lang-ja .l-top-description .top-description-inner .button {
        margin-top: 280px; } }

.lang-ja .l-top-about .section-inner {
  background-position: left bottom; }

.lang-ja .l-top-fes {
  position: relative;
  height: auto;
  padding: 0;
  background: url(../img/top-fes-bg.png) no-repeat center bottom;
  background-size: cover; }
  .lang-ja .l-top-fes .section-inner {
    padding: 100px 0 50px 0; }
  @media screen and (max-width: 767px) {
    .lang-ja .l-top-fes {
      padding: 50px 0 0 0; }
      .lang-ja .l-top-fes .section-inner {
        padding: 50px 0;
        background-image: url(../img/top-fes-bg_sp.png);
        background-size: cover;
        background-position: left bottom; } }

.lang-ja .l-footer {
  position: relative;
  top: calc(100vh + 500px);
  z-index: 99; }

@media screen and (max-width: 767px) {
  .lang-ja .footer-heading {
    text-align: left;
    padding-left: 10px; } }

@media screen and (min-width: 415px) {
  .pc-hide {
    display: none; } }

@media screen and (max-width: 767px) {
  .sp-hide {
    display: none !important; } }

.mb50 {
  margin-bottom: 50px !important; }
  @media screen and (max-width: 767px) {
    .mb50 {
      margin-bottom: 25px !important; } }

.mb35 {
  margin-bottom: 35px !important; }
  @media screen and (max-width: 767px) {
    .mb35 {
      margin-bottom: 20px !important; } }

.txtC {
  text-align: center !important; }

.ma {
  margin-left: auto !important;
  margin-right: auto !important; }
