.l-breadcrumb {
  width: 1070px;
  margin: 0 auto 70px auto;
  padding: 15px 0;
  ul {
    // @include clearfix;
    li {
      // float: left;
      display: inline;
      font-size: 13px;
      color: #4c4c4c;
      &:after {
        content: "";
        display: inline-block;
        width: 6px;
        height: 11px;
        margin: 0 10px;
        background-image: url(../img/breadcrumb-arrow.png);
        background-repeat: no-repeat;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      a {
        text-decoration: underline;
      }
    }
  }
  @include mqSP {
    width: 100%;
    padding: 15px 10px;
    margin-bottom: 20px;
    ul {
      li {
        font-size: 10px;
        &:after {
          width: 3px;
          height: 6px;
          margin: 0 10px;
          background-size: 3px auto;
        }
      }
    }
  }
}

.exporting_list {
  width: 600px;
  margin: 0 auto 110px auto;
  li {
    position: relative;
    border: 2px solid rgba(144,193,228,.8);
    background: rgba(233,237,242,.8);
    border-radius: 8px;
    padding: 15px 0;
    text-align: center;
    font-size: 30px;
    line-height: 1.57;
    color: #05427f;
    margin: 0 auto 48px auto;
    &:after {
      content: "";
      position: absolute;
      bottom: -48px;
      left: 0;
      width: 100%;
      height: 48px;
      background-image: url(../img/arrow-list.png);
      background-position: center;
      background-repeat: no-repeat;
    }
    &:last-child {
      margin-bottom: 0;
      &:after {
        display: none;
      }
    }
    p {
      font-size: 16px;
      color: #000;
      a {
        color: #05427f;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
    span {
      font-size: 20px;
      padding: 0 0 0 10px;
      br + & {
        padding: 0;
      }
    }
  }
  @include mqSP {
    width: 100%;
    padding: 0 15px;
    margin: 0 auto 30px auto;
    li {
      font-size: 20px;
      &:after {
        background-size: 42px auto;
      }
      p {
        font-size: 13px;
      }
      span {
        font-size: 13px;
      }
    }
  }
}