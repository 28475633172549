.button {
  position: relative;
  display: block;
  width: 214px;
  height: 42px;
  margin: 45px auto 0 auto;
  font-size: 16px;
  text-align: center;
  color: #fff;
  line-height: 42px;
  cursor: pointer;
  &:hover {
    opacity: .7;
  }
  &:before,
  &:after {
    content: "";
    position: absolute;
    z-index: 0;
    width: 216px;
    background: #211E55;
    height: 32px;
  }
  &:before {
    left: -6px;
    bottom: 0;
  }
  &:after {
    right: -6px;
    top: 0;
  }
  span {
    position: relative;
    z-index: 1;
    display: block;
    width: 216px;
    height: 42px;
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
    }
    &:before {
      top: 0;
      left: -6px;
      border-width: 0 0 10px 10px;
      border-color: transparent transparent #211E55;
    }
    &:after {
      bottom: 0;
      right: -4px;
      border-width: 10px 10px 0 0;
      border-color: #211E55 transparent transparent transparent;
    }
  }
  @include mqSP {
    margin-top: 25px;
  }
}

.button-icon {
  position: absolute;
  right: 5px;
  top: 0;
  z-index: 2;
  width: 8px;
  height: 100%;
  background-image: url(../img/icon-arrow_sp.png);
  background-position: center;
  background-size: 7px auto;
  background-repeat: no-repeat;
}