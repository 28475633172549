.l-article {
  max-width: 800px;
  margin: 0 auto;
  h1 {
    font-size: 30px;
    margin: 0 0 40px 0;
    padding: 30px 0;
    border-bottom: 1px solid #000;
  }
  @include mqSP {
    padding: 0 15px;
    h1 {
      font-size: 17px;
      margin: 0 0 20px 0;
      padding: 15px 0;
    }
  }
}
.article-date {
  font-size: 16px;
  @include mqSP {
    font-size: 12px;
  }
}
.article-body {
  font-size: 16px;
  line-height: 1.875;
  margin-bottom: 100px;
  p {
    margin-bottom: 30px;
  }
  @include mqSP {
    font-size: 14px;
    margin-bottom: 50px;
    p {
      margin-bottom: 15px;
    }
  }
}