.section-heading {
  margin: 0 0 45px 0;
  padding: 0 0 30px 0;
  font-size: 40px;
  text-align: center;
  line-height: 1.3;
  background-image: url(../img/mark.png);
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 40px auto;
  @include mqSP {
    font-size: 21px;
    margin: 0 0 15px 0;
    padding: 0 10px 25px 10px;
    background-image: url(../img/mark_sp.png);
    background-size: 30px auto;
  }
}
.section-text {
  font-size: 16px;
  line-height: 1.875;
  text-align: center;
  @include mqSP {
    padding: 0 10px;
    font-size: 12px;
  }
}

.section-inner {
  width: 1070px;
  margin: 0 auto;
  @include mqSP {
    width: 100%;
  }
}

.section-heading_sub {
  font-size: 39px;
  margin: 80px 0 45px 0;
  text-align: center;
  @include mqSP {
    font-size: 20px;
    margin: 20px 0 15px 0;
  }
}

.section-heading_green {
  font-size: 30px;
  color: #91cd02;
  text-align: center;
  margin: 0 0 40px 0;
  @include mqSP {
    font-size: 20px;
    margin: 30px 0 15px 0;
  }
}
.section-heading_blue {
  font-size: 26px;
  color: #0d3387;
  margin: 0 0 30px 0;
  text-align: center;
  @include mqSP {
    font-size: 18px;
    line-height: 1.4;
  }
}

.section_white {
  background: #fff;
  border: 2px solid #e6e6e6;
  padding: 35px;
  text-align: center;
  width: 1072px;
  margin: 100px auto;
  img {
    max-width: 100%;
  }
  @include mqSP {
    width: calc(100% - 30px);
    padding: 15px;
    margin: 40px auto;
  }
}