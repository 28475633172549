@charset "UTF-8";

.form-section {
  &__body {
    ._txt_top {
      margin: 0 0 45px 0;
      padding: 0 0 30px 0;
      font-size: 16px;
      text-align: center;
      background-image: url(../img/mark.png);
      background-position: bottom center;
      background-repeat: no-repeat;
      background-size: 40px auto;
    }
    ._txt {
      text-align: center;
      @include fz(16);
      line-height: 30px;
      margin-bottom:50px;
      &:last-child {
        margin-bottom: 0;
        color: #808080;
        font-size: 15px;
        line-height: 1.466;
      }
      a {
        text-decoration: underline;
      }
    }
    ._txt_bottom {
      text-align: center;
      @include fz(16);
      line-height: 30px;
      margin: 40px 0 50px 0;
    }
    sup {
      @include fz(5);
      vertical-align: super;
      color: #cd2929;
    }
  }
  @include mqSP {
    padding: 0 20px;
    width: calc(100% - 20px);
    margin: 0 auto 20px auto;
    &__body {
      ._txt_top {
        @include fz(12);
        line-height: 22px;
        text-align: left;
      }
      ._txt {
        @include fz(12);
        line-height: 22px;
        text-align: left;
        margin-bottom: 20px;
        &:last-child {
          font-size: 12px;
        }
      }
      ._txt_bottom {
        @include fz(12);
        line-height: 22px;
        text-align: left;
        margin-bottom: 20px;
      }
      sup {
        @include fz(5);
      }
    }
  }
}

%is-error {
  background-color: #fff7f8;
  background-image: url(../img/icon_error.png);
  background-repeat: no-repeat;
  background-size: 18px auto;
  background-position: 98% center;
  border: 1px solid #cd2929;
}

.form-cont {
  display:table;
  width:685px;
  margin:0 auto;
  .error {
    display: block;
    font-size: 13px;
    padding-top: 5px;
    color: #cd2929;
  }
  dl {
    display:table-row;
  }
  dt,dd {
    display:table-cell;
    @include fz(16);
    padding:15px 15px 15px 0;
  }
  input {
    @include fz(14);
    padding:10px;
    border: 1px solid #CCCCCC;
    &.is-error {
      @extend %is-error;
    }
  }
  input {
    width:500px;
  }
  ._form-message {
    dt {
      vertical-align:top;
    }
    dd {
      padding-bottom: 0;
      textarea {
        @include fz(14);
        width:500px;
        height:300px;
        border: 1px solid #CCCCCC;
        &.is-error {
          @extend %is-error;
        }

      }
    }
  }
  @include mqSP {
    width:100%;
    dl {
      display: block;
    }
    dt {
      text-align: left;
    }
    dt,dd {
      display: block;
      @include fz(14);
      padding:10px 0px 0 0;
    }
    ._form-name {
      input {
        width:100%;
      }
    }
    ._form-mail,
    ._form-mail-confirmation {
      input {
        width:100%;
      }
    }
    ._form-message {
      dd {
        textarea {
          width:100%;
          height:150px;
        }
      }
    }
  }
}

.form-btn {
  width: 100%;
  margin: 40px 0 20px 0;
  .button {
    position: relative;
    input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
  @include mqSP {
    margin: 20px 0;
  }
}

input:focus,
textarea:focus {
  background: #eaf0fc;
  box-shadow: 0px 0px 7px #254792;
  outline: none;
}
