.table_fes {
  display: flex;
  width: 800px;
  margin: 0 auto 20px auto;
  dt {
    width: 150px;
    font-size: 16px;
    border-right: 2px solid #1040a9;
    color: #1040a9;
    padding: 15px 0;
  }
  dd {
    flex: 1;
    font-size: 16px;
    line-height: 1.5;
    padding: 15px 0 15px 30px;
    a {
      color: #0d3387;
      text-decoration: underline;
    }
  }
  @include mqSP {
    display: block;
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    dt {
      display: inline-block;
      border-right: none;
      border-bottom: 2px solid #1040a9;
      font-size: 14px;
    }
    dd {
      width: 100%;
      font-size: 12px;
      padding: 15px 0;
    }
  }
}