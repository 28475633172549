.l-footer {
  min-width: 1070px;
  background: #1a1a1a;
  @include mqSP {
    min-width: 0;
  }
}
.footer-inner {
  position: relative;
  width: 1070px;
  margin: 0 auto;
  @include mqSP {
    width: 100%;
  }
}
.footer-instagram {
  display: none;
  @include mqSP {
    display: block;
    width: 100%;
    height: 65px;
    border-bottom: 1px solid #a4a4a4;
    a {
      display: block;
      width: 100%;
      height: 65px;
      background-image: url(../img/icon-insta.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 25px auto;

    }
  }
}
.footer-heading {
  color: #fff;
  font-size: 20px;
  padding: 40px 0;
  @include mqSP {
    text-align: center;
    padding: 20px 10px 10px 0;
    font-size: 16px;
  }
}
.footer-nav {
  position: relative;
  border-bottom: 1px solid #a4a4a4;
  ul {
    @include clearfix;
    position: absolute;
    right: 0;
    top: 45px;
    li {
      float: left;
      font-size: 14px;
      &:before {
        content: "|";
        color: #fff;
      }
      &:first-child {
        &:before {
          display: none;
        }
      }
      a {
        color: #fff;
        padding: 0 15px;
      }
      &:last-child {
        a {
          padding-right: 0;
        }
      }
    }
  }
  @include mqSP {
    ul {
      position: static;
      padding-bottom: 15px;
      li {
        float: none;
        font-size: 12px;
        &:before {
          display: none;
        }
        a {
          display: block;
          text-decoration: underline;
          padding: 6px 10px;
        }
      }
    }
  }
}
.footer-copy {
  color: #808080;
  font-size: 11px;
  padding: 25px 0;
  p {
    width: 1070px;
    margin: 0 auto;
  }
  @include mqSP {
    padding: 17px 0;
    font-size: 9px;
    p {
      width: 100%;
      text-align: center;
    }
  }
}