.l-page-header {
  position: relative;
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  &.page-header_inquiry {
    background-image: url(../img/inquiry-heading.png);
  }
  &.page-header_brands {
    background-image: url(../img/ourbrands-heading.png);
  }
  &.page-header_exporting {
    background-image: url(../img/exporting-heading.png);
  }
  &.page-header_notfound {
    background-image: url(../img/inquiry-heading.png);
  }

  &.page-header_policy {
    background-image: url(../img/policy-heading.png);
  }
  &.page-header_plants {
    background-image: url(../img/plants-heading.png);
  }
  &.page-header_fes {
    background-image: url(../img/fes-heading.png);
  }
  &.page-header_about {
    background-image: url(../img/about-heading.png);
  }
  &.page-header_information {
    background-image: url(../img/information-heading.png);
  }


  span {
    display: block;
    text-align: center;
    color: #fff;
    width: 100%;
    text-shadow: 0px 0px 4.65px rgba(7, 0, 2, 0.75);
    font-size: 36px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;
    letter-spacing: .08em;
  }
  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: #211E55;
    opacity: .67;
  }
  @include mqSP {
    height: 100px;
    span {
      font-size: 21px;
    }
  }
}