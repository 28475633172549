// 日本語グランドデザインsass

.lang-ja {

  // header
  .l-header {
    z-index: 3;
    @include mqSP {
      .header-lang {
        background: rgba(33,30,85,.95);
      }
    }
    &.is-pageTop {
      height: 32px;
      z-index: 1000;
      position: fixed;
      top: 0;
      left: 0;
      overflow: hidden;
      // opacity: 0;
      // @include animationValue(animation, 0.7, ease, 7 , 1, forwards);
      @include mqSP {
        position: fixed !important;
        overflow: visible;
        height: 60px;
      }
    }
    &.is-fixed {
      @include mqSP {
        position: fixed !important;
        height: 60px !important;
        .header-logo a {
          width: 131px;
          height: 39px;
          background-image: url(../img/logo_sp.png);
          background-size: auto 39px;
        }
      }
    }
  }

  // KV
  .l-top-kv {
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    z-index: 10;
    transition: all .5s cubic-bezier(1, 0, 0, 1);
    &.is-scroll {
      top: -200vh;
    }
  }
  .top-kv-inner {
    height: 100%;
  }
  .top-kv-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .top-kv-slideshow {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0;
    @include animationValue(animation, 0.5, ease, 0 , 1, forwards);
    > div {
      opacity: 0;
      transition: opacity .3s ease;
    }

    &.is-loaded {
      > div {
        position: absolute;
        opacity: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        z-index: 0;
        &.is-current {
          z-index: 2;
          @include animationValue(animation3, 10, ease, 0 , 1, forwards);
        }
        &.is-next {
          z-index: 1;
        }
      }
    }

  }
  .top-kv-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    padding-top: 15%;
    @include mqSP {
      padding-top: 35%;
    }


    h1 {
      display: none;
      text-shadow: 1px 1px 10px #000;
      color: #fff;
      font-size: 40px;
      text-align: center;
      margin-bottom: 30px;
      letter-spacing: .02em;
      span {
        transform: scale(.8);
        display: inline-block;
        transition: all 1s ease;
        opacity: 0;
        &.is-show {
          transform: scale(1);
          display: inline-block;
          opacity: 1;
        }
      }
      @include mqSP {
        font-size: 25px;
        line-height:2;
        width: 27rem;
        text-align: center;
        margin: 0 auto 5% auto;
      }
    }
    h2 {
      text-shadow: 1px 1px 10px #000;
      color: #fff;
      font-size: 18px;
      text-align: center;
      line-height: 1.8em;
      position: relative;
      opacity: 0;
      top: 15px;
      @include animationValue(animation2, 1.5, ease, 7 , 1, forwards);
      @include mqSP {
        font-size: 14px;
        line-height: 2;
      }
    }
    .logo {
      position: absolute;
      bottom: 20%;
      left: 50%;
      transform: translate3d(-50%,0,0);
      opacity: 0;
      @include animationValue(animation, 3, ease, 8, 1, forwards);
      @include mqSP {
        display: none;
      }
    }
    .scroll {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate3d(-50%,0,0);
      opacity: 0;
      @include animationValue(animation, 0.7, ease, 9 , 1, forwards);
    }
  }

  .l-header.is-fixed+.l-top-kv {
      margin-top: 0;
  }

  .l-main {
    top: calc(100vh + 500px);
    position: relative;
    z-index: 99;
    background: url(../img/top-bg.png);
  }

  // インフォメーション
  .l-top-information {
    .section-inner {
      padding: 60px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    h1 {
      position: relative;
      font-size: 26px;
      line-height: 1;
      width: 350px;
      font-weight: bold;
      &:after {
        content: "";
        width: 60px;
        height: 1px;
        background: #000;
        position: absolute;
        top: 50%;
        right: 40px;
      }
    }
    ul {
      width: 100%;
      flex: 1;
      li {
        margin: 0 0 10px 0;
        font-size: 16px;
        &:lats-child {
          margin-bottom: 0;
        }
        a {
          text-decoration: underline;
        }
      }
    }
    @include mqSP {
      padding: 40px 0;
      .section-inner {
        display: block;
        padding: 0 10px;
      }
      h1 {
        font-size: 21px;
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        &:after {
          display: none;
        }
      }
      ul {
        li {
          font-size: 12px;
          line-height: 1.6;
        }
      }
    }
  }


  // 全国有数の植木の生産地
  .l-top-description {
    position: relative;
    background: #eef3f9 !important;
    .top-description-inner {
      background: url(../img/top-description-bg.png) no-repeat right top;
      height: 537px;
      padding: 145px 0 0 0;
      .section-heading,
      .section-text,
      .button-wrapper {
        width: 539px;
      }
    }
    @include mqSP {
      .top-description-inner {
        padding: 50px 0 0 0;
        background-image: url(../img/top-description-bg_sp.png);
        background-size: 80% auto;
        background-position: bottom center;
        height: 570px;
        .section-heading,
        .section-text,
        .button-wrapper {
          width: 100%;
        }
        .section-heading {
          text-align: center;
        }
        .button {
          margin-top: 280px;
        }
      }
    }
  }

  // 匝瑳の植木紹介
  .l-top-about {
    .section-inner {
      background-position: left bottom;
    }
  }

  // 植木まつり
  .l-top-fes {
    position: relative;
    height: auto;
    padding: 0;
    background: url(../img/top-fes-bg.png) no-repeat center bottom;
    background-size: cover;
    .section-inner {
      padding: 100px 0 50px 0;
    }
    @include mqSP {
      padding: 50px 0 0 0;
      .section-inner {
        padding: 50px 0;
        background-image: url(../img/top-fes-bg_sp.png);
        background-size: cover;
        background-position: left bottom;
      }
    }
  }




  // footer
  .l-footer {
    position: relative;
    top: calc(100vh + 500px);
    z-index: 99;
  }
  .footer-heading {
    @include mqSP {
      text-align: left;
      padding-left: 10px;
    }
  }

}

