.pagetop {
  position: fixed;
  right: 40px;
  bottom: 40px;
  opacity: 0;
  transition: all .3s ease;
  a {
    @include texthidden;
    display: block;
    width: 76px;
    height: 76px;
    background-color: rgba(0,0,0,.8);
    background-image: url(../img/pagetop.png);
    background-size: 58px auto;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 6px;
  }
  &.is-show {
    opacity: 1;
  }
  @include mqSP {
    width: 46px;
    height: 46px;
    right: 10px;
    bottom: 15px;
    a {
      width: 46px;
      height: 46px;
      background-color: rgba(51,51,51,.8);
      background-size: 29px auto;
    }
  }
}
