.l-header {
  width: 100%;
  min-width: 1070px;
  height: 150px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0,0,0,.3);
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 1070px;
    height: 32px;
    background: #1a1a1a;
  }
  &.is-fixed {
    position: fixed;
    z-index: 2;
    top: -200px;
    height: 110px;
    transition: top .3s ease;
  }
  &.is-show {
    top: 0;
  }
  @include mqSP {
    position: relative !important;
    z-index: 1;
    min-width: 0;
    height: 60px;
    overflow: hidden;
    &:before {
      height: 4px;
    }
    &.is-open {
      overflow: visible;
    }
  }
}
.header-inner {
  position: relative;
  width: 1070px;
  margin: 0 auto;
  @include mqSP {
    width: 100%;
  }
}
.header-hamburger {
  display: none;
  @include mqSP {
    display: block;
    position: absolute;
    top: 13px;
    right: 10px;
    a {
      @include texthidden;
      display: block;
      width: 30px;
      height: 32px;
      background-image: url(../img/icon-menu.png);
      background-repeat: no-repeat;
      background-size: 30px auto;
      background-position: 0 0;
      .is-open & {
        width: 27px;
        height: 32px;
        background-image: url(../img/icon-close.png);
        background-size: 27px auto;
      }
    }
  }
}
.header-menu {
  @include mqSP {
    position: absolute;
    z-index: 0;
    top: 60px;
    left: 0;
    width: 100%;
    opacity: 0;
    transition: opacity .2s ease;
    .is-open & {
      opacity: 1;
    }
  }
}
.header-nav {
  position: absolute;
  right: 0;
  top: 84px;
  ul {
    @include clearfix;
    li {
      float: left;
      font-size: 16px;
      &:before {
        content: "|";
        color: #000;
      }
      &:first-child {
        &:before {
          display: none;
        }
      }
      a {
        color: #000;
        padding: 0 15px;
        &.is-current {
          color: #211E55;
          text-decoration: underline;
          pointer-events: none;
        }
      }
      &:last-child {
        a {
          padding-right: 0;
        }
      }
    }
  }
  .l-header.is-fixed & {
    top: 65px;
  }
  @include mqSP {
    position: static;
    width: 100%;
    background: rgba(33,30,85,.95);
    ul {
      li {
        float: none;
        &:before {
          display: none;
        }
        a {
          display: block;
          padding: 15px 10px;
          border-bottom: 1px solid #999;
          font-size: 15px;
          color: #fff;
          &.is-current {
            color: #fff;
            pointer-events: auto;
            text-decoration: none;
          }
        }
      }
    }
  }
}

.header-lang-tite {
  display: none;
  @include mqSP {
    display: block;
    padding: 12px 10px 8px 10px;
    font-size: 15px;
    color: #fff;
  }
}

.header-lang {
  ul {
    @include clearfix;
    position: absolute;
    right: 30px;
    top: 10px;
    li {
      float: left;
      font-size: 12px;
      &:before {
        content: "|";
        color: #808080;
      }
      &:first-child {
        &:before {
          display: none;
        }
      }
      a {
        color: #808080;
        padding: 0 10px;
        &.is-current {
          pointer-events: none;
          color: #fff;
        }
        &:hover {
          color: #fff;
        }
      }
      &:last-child {
        a {
          padding-right: 0;
        }
      }
    }
  }
  @include mqSP {
    width: 100%;
    padding: 0 0 12px 0;
    background: rgba(13,51,135,.95);
    border-bottom: 1px solid #999;
    ul {
      position: static;
      li {
        font-size: 12px;
        a {
        }
      }
    }
  }
}

.header-instagram {
  position: absolute;
  right: 0;
  top: 7px;
  width: 18px;
  height: 18px;
  a {
    @include texthidden;
    width: 18px;
    height: 18px;
    background-image: url(../img/icon-insta.png);
    background-repeat: no-repeat;
    background-size: 18px auto;
    background-position: 0 0;
  }
  @include mqSP {
    display: none;
  }
}
.header-logo {
  position: absolute;
  left: 0;
  top: 44px;
  a {
    @include texthidden;
    width: 80px;
    height: 98px;
    background-image: url(../img/logo.png);
    background-repeat: no-repeat;
    background-size: auto 98px;
    background-position: 0 0;
  }
  .l-header.is-fixed & {
    a {
      width: 206px;
      height: 56px;
      background-image: url(../img/logo_scroll.png);
      background-size: auto 56px;
    }
  }
  @include mqSP {
    top: 13px;
    left: 10px;
    a,
    .l-header.is-fixed a {
      width: 131px;
      height: 39px;
      background-image: url(../img/logo_sp.png);
      background-size: auto 39px;
    }
  }
}