.l-top-kv {
  position: relative;
  top: 0px;
  z-index: 0;
  // height: 100vh;
  width: 100%;
  height: 490px;
  overflow: hidden;
  @include mqPC {
    .l-header.is-fixed + & {
      margin-top: 150px;
    }
  }
  @include mqSP {
    height: 320px;
  }


}
.top-kv-inner {
  width:100%;
  height: 490px;
  @include mqSP {
    height: 320px;
  }
}
.top-kv-slide {
  position: relative;
  width: 100%;
  height: 490px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @include mqSP {
    height: 320px;
  }
  &.slide01 {
    background-image: url(../img/main1.png);
    @include mqSP {
      background-image: url(../img/main1_sp.png);
    }
  }
  &.slide02 {
    background-image: url(../img/main2.png);
    @include mqSP {
      background-image: url(../img/main2_sp.png);
    }
  }
  &.slide03 {
    background-image: url(../img/main3.png);
    @include mqSP {
      background-image: url(../img/main3_sp.png);
    }
  }
}

.top-kv-copy {
  text-shadow: 1px 1px 10px #000;
  font-weight: bold;
  line-height: 1.2;
  color: #fff;
  font-size: 40px;
  width: 1070px;
  margin: 0 auto;
  padding: 60px 30px;
  span {
    font-size: 50px;
  }
  @include mqPC {
    .slide03 & {
      padding-top: 80px;
    }
  }
  @include mqSP {
    position: absolute;
    top: 60%;
    left: 0;
    width: calc(100% - 20px);
    padding: 0 10px;
    font-size: 18px;
    text-shadow: none;
    line-height: 1.1;
    span {
      font-size: 23px;
    }
    .zh-tw &,
    .zh-cn & {
      font-size: 19px;
      line-height: 1.3;
      span {
        font-size: 25px;
      }
    }
    .slide01 & {
      &:after {
        content: "";
        position: absolute;
        bottom: -15px;
        left: 10px;
        width: 50px;
        height: 1px;
        background: #fff;
      }
    }
    .slide03 & {
      line-height: 1.3;
    }
  }
}