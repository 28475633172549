/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}


//
// original theme
//
.dot-class {
    position: absolute;
    bottom: 20px;
    text-align: center;
    width: 100%;
    li {
        position: relative;
        display: inline-block;
        width: 10px;
        height: 10px;
        margin: 0 8px;
        border-radius: 50%;
        overflow: hidden;
        text-indent: 100%;
        overflow: hidden;
        word-wrap: initial;
        font-size: 0;
        border: 1px solid #fff;
        cursor: pointer;
        &.slick-active {
            background: #fff;
        }
        button {
            opacity: 0;
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
    @include mqSP {
        bottom: 10px;
    }
}
.slick-arrow {
    position: absolute;
    z-index: 999;
    top: 50%;
    -webkit-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    width: 30px;
    height: 30px;
    /* background-color: white; */
    transform-origin: center center;
    -webkit-transform-origin: top left;
    cursor: pointer;
    background: none;
    text-indent: 100%;
    overflow: hidden;
    word-wrap: initial;
    font-size: 0;
    @include mqSP {
        opacity: 0;
    }
}
.slick-next {
    right: 3.5%;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    @include mqSP {
        // right: 5.5%;
    }
}

.slick-prev {
    left: 2.5%;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    border-top: 2px solid white;
    border-left: 2px solid white;
}
