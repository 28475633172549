.l-top-about {
  position: relative;
  height: auto;
  padding: 0;
  &:after {
    content: "";
    // position: absolute;
    display: block;
    position: relative;
    bottom: 0;
    width: 100%;
    height: 240px;
    margin: 0;
    background-image: url(../img/top-pic.png);
    background-repeat: repeat-x;
    background-position: center;
  }
  .section-inner {
    padding: 100px 0 50px 0;
    background: url(../img/top-about-bg.png) no-repeat left top;
  }
  @include mqSP {
    padding: 50px 0 0 0;
    &:after {
      background-image: url(../img/top-pic_sp.png);
      background-size: cover;
      height: 250px;
    }
    .section-inner {
      padding: 50px 0;
      background-image: url(../img/top-about-bg_sp.png);
      background-size: cover;
      background-position: left bottom;
    }
  }
}

.l-top-export {
  position: relative;
    background: url(../img/top-export-bg-base.png) repeat;
  .top-export-inner {
    background: url(../img/top-export-bg.png) no-repeat right top;
    height: 537px;
    padding: 145px 0 0 0;
    .section-heading,
    .section-text,
    .button-wrapper {
      width: 539px;
    }
    .section-heading {
      text-align: left;
    }
  }
  @include mqSP {
    .top-export-inner {
      padding: 50px 0 0 0;
      background-image: url(../img/top-export-bg_sp.png);
      background-size: cover;
      .section-heading,
      .section-text,
      .button-wrapper {
        width: 100%;
      }
      .section-heading {
        text-align: center;
      }
      .button {
        margin-top: 280px;
      }
    }
  }
}

.l-top-contact {
  padding: 100px 0 0 0;
  @include mqSP {
    padding-top: 50px;
  }
}