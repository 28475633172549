.page-section {
  ._txt {
    width: 1000px;
    margin: 0 auto 50px auto;
    text-align: center;
    @include fz(16);
    line-height: 30px;
    &:last-child {
      margin-bottom: 0;
      color: #808080;
      font-size: 15px;
      line-height: 1.466;
    }
    img {
      max-width: 100%;
    }
    a {
      text-decoration: underline;
    }
    span {
      width: 800px;
      display: block;
      font-size: 13px;
      padding: 20px 0 0 0;
      margin: 0 auto;
    }
    @include mqSP {
      width: 100%;
      @include fz(12);
      line-height: 22px;
      text-align: left;
      margin-bottom: 20px;
      padding: 0 15px;
      span {
        width: 100%;
        font-size: 10px;
      }
    }
  }
  ._txt_sub {
    font-size: 14px;
    width: 1000px;
    margin: -20px auto 50px auto;
    text-align: center;
    color: #666;
    @include mqSP {
      width: 100%;
      margin: 0 auto;
      font-size: 12px;
    }
  }
  ._txt_big {
    font-size: 20px;
    width: 1000px;
    margin: 80px auto;
    text-align: center;
    @include mqSP {
      width: 100%;
      padding: 0 15px;
      box-sizing: border-box;
      font-size: 16px;
      margin: 40px auto;
    }

  }
}

.page-heading {
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 10px 0;
  @include mqSP {
    font-size: 16px;
  }
}

.page-content_2col {
  display: flex;
  flex-direction: row-reverse;
  width: 1072px;
  margin: 0 auto 100px auto;
  img {
    max-width: 440px;
  }
  ._txt {
    text-align: left;
    font-size: 16px;
    line-height: 1.875;
    padding: 0 40px 0 0;
    flex: 1;
    color: #000 !important;
    line-height: 1.875 !important;
  }
  @include mqSP {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    display: block;
    margin-bottom: 40px;
    img {
      max-width: 100%;
    }
    ._txt {
      margin-top: 15px;
      padding: 0;
      font-size: 12px !important;
    }
  }
}
.page-content_2col_reverce {
  display: flex;
  width: 1072px;
  margin: 0 auto 100px auto;
  img {
    max-width: 440px;
  }
  ._txt {
    text-align: left;
    font-size: 16px;
    line-height: 1.875;
    padding: 0 0 0 40px;
    flex: 1;
    color: #000 !important;
    line-height: 1.875 !important;
  }
  @include mqSP {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    display: block;
    margin-bottom: 40px;
    img {
      max-width: 100%;
    }
    ._txt {
      margin-top: 15px;
      font-size: 12px !important;
      padding: 0;
    }

  }
}
.page-content_2col_reverce_map {
  display: flex;
  width: 1072px;
  margin: 0 auto 100px auto;
  img {
    max-width: 600px;
  }
  ._txt {
    text-align: left;
    line-height: 1.875;
    padding: 0 0 0 40px;
    flex: 1;
    color: #000 !important;
    line-height: 1.875 !important;
    h3 {
      color: #1040a9;
      font-size: 18px;
    }
    p {
      margin: 0 0 20px 0;
      font-size: 14px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  @include mqSP {
    width: 100%;
    display: block;
    padding: 0 15px;
    img {
      max-width: 100%;
    }
    ._txt {
      margin: 15px 0 0 0;
      padding: 0;
      h3 {
        font-size: 14px;
      }
      p {
        font-size: 12px;
      }

    }
  }
}

.page-content_3col {
  display: flex;
  justify-content: space-between;
  width: 1072px;
  margin: 0 auto 100px auto;
  align-items: center;
  img {
    max-width: 100%;
  }
  @include mqSP {
    width: 100%;
    display: block;
    text-align: center;
    img {
      margin: 0 0 5px 0;
    }
  }

}

.page-content-products {
  display: flex;
  width: 1072px;
  margin: 0 auto 60px auto;
  &:last-child {
    margin-bottom: 0;
  }
  > p img {
    width: 400px;
  }
  ._txt {
    position: relative;
    flex: 1;
    padding: 0 180px 0 40px;
    h3 {
      font-size: 24px;
      text-align: left;
      color: #000;
      margin: 0 0 20px 0;
    }
    p {
      text-align: left;
      font-size: 15px;
      color: #000 !important;
      line-height: 1.875 !important;
      padding: 0 0 30px 0;
      &.name {
        position: absolute;
        bottom: 0;
        right: 180px;
        padding: 0;
      }
    }
    img {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  @include mqSP {
    display: block;
    padding: 0 15px;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    > p img {
      max-width: 100%;
      width: 100%;
    }
    ._txt {
      margin-top: 15px;
      margin: 15px auto 0 auto;
      padding: 0 110px 0 0;
      h3 {
        font-size: 16px;
        margin-bottom: 15px;
      }
      p {
        font-size: 13px;
        &.name {
          position: static;
          text-align: right;
        }
      }
      img {
        width: 90px;
      }
    }
  }
}