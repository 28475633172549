.page_policy {
  width: 800px;
  margin: 0 auto;
  font-size: 16px;

  h3 {
    margin: 45px 0 15px 0;
    font-size: 19px;
    font-weight: bold;
  }
  p {
    line-height: 1.875;
  }
  ul {
    margin: 10px 0 0 0;
    li {
      margin-bottom: 10px;
      position: relative;
      padding: 0 0 0 18px;
      &:before {
        content: "・";
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  @include mqSP {
    width: 100%;
    padding: 0 15px;
    font-size: 12px;
    h3 {
      font-size: 16px;
      margin: 20px 0 15px 0;
    }
  }
}